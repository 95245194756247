<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div :class="['form-col', $attrs['class']]">

        <label v-if="label" :for="name">{{ label }}</label>

        <amount-input
          v-bind="{...inputAttrs, ...getAmountProps(props)}"
          :value="props.value"
          :class="inputClass"
        >
          <template v-slot:before>
            <slot name="before" />
          </template>

          <template v-slot:after>
            <slot name="after" />
          </template>
        </amount-input>

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
import { FinalField } from "vue-final-form";
import {omit} from "lodash-es";
import AmountInput from "@/components/ui/AmountInput";
import FieldError from "@/components/form/FieldError";

export default {
  name: "AmountField",

  components: {
    FinalField,
    AmountInput,
    FieldError,
  },

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    validate: {
      type: [Function, Array],
      required: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    textClass: {
      type: String,
      default: 'text-right'
    },
    inputClass: {
      type: String,
    },
    suppressError: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  computed: {
    inputAttrs(){
      return omit(this.$attrs, 'class');
    },
  },

  methods: {
    handleInput(value){
      this.$refs.field.fieldState.change(value);
    },

    getAmountProps(props){
      if(this.editMode){
        return {
          name: props.name,
          amountClass: ['form-input', this.textClass, { error: props.meta.error && props.meta.touched }],
          onFocus: props.events.focus,
          onBlur: props.events.blur,
          onInput: this.handleInput,
        };
      }

      return {
        amountClass: this.textClass,
        readOnly: true,
      };
    },
  }
};
</script>
