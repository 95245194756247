<template>
<div>
   <FinalForm
      class="h-full"
      ref="form"
    >
      <div class="h-full flex flex-col">
          <div class="form-row">
              <button
                  class="main-topic button-color text-gray-500 focus:outline-none font-semibold py-2 px-4 border"
                  :class="{ activeCatg: 'charge' === selectedCatg }"
                  @click="selectedCatg = 'charge'">
                  charge
              </button>
              <button
                  class="customer button-color text-gray-500 focus:outline-none font-semibold py-2 px-4 border"
                  :class="{ activeCatg: 'credit' === selectedCatg }"
                  @click="selectedCatg = 'credit'">
                  credit
              </button>
          </div>
          <div v-if="selectedCatg === 'charge'">
              <div class="form-row">
                  <div class="form-col">
                      <label for="categoryReport">charge code category</label>
                      <Dropdown
                          id="categoryReport"
                          :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                          multiple
                      />
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <label for="categoryReport">charge code</label>
                      <Dropdown
                          id="categoryReport"
                          :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                          multiple
                      />
                  </div>
              </div>
              <div class="form-row">
                  <input type="checkbox"  id="prorate_charge" class="w-4 h-4 mt-1" />
                  <label for="prorate_charge" class="ml-2 font-bold">prorate charge</label>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <label for="post_date">post date</label>
                      <date-picker name="post_date" ></date-picker>
                  </div>
                  <div class="form-col">
                      <label for="due_date">due date</label>
                      <date-picker name="due_date" ></date-picker>
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <button
                          class="flex items-center border-b font-bold focus:outline-none mb-4 button-color-link mt-10"
                          type="button"
                          >
                          override
                      </button>
                  </div>
                  <div class="form-col">
                    <AmountField
                        name="amount_charge"
                        label="amount"
                        :edit-mode="true"
                        :compute-width="false"
                        text-class="text-left"
                      />
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">

                          <label >
                            description
                          </label>

                      <textarea
                      type="text"
                      class="form-input"
                      />
                  </div>
              </div>
          </div>
          <div v-else>
                          <div class="form-row">
                  <div class="form-col">
                      <label for="categoryReport">charge code category</label>
                      <Dropdown
                          id="categoryReport"
                          :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                          multiple
                      />
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <label for="categoryReport">charge code</label>
                      <Dropdown
                          id="categoryReport"
                          :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                          multiple
                      />
                  </div>
              </div>
              <div class="form-row">
                  <input type="checkbox"  id="prorate_charge" class="w-4 h-4 mt-1" />
                  <label for="prorate_charge" class="ml-2 font-bold">prorate charge</label>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <label for="post_date">post date</label>
                      <date-picker name="post_date" ></date-picker>
                  </div>
                  <div class="form-col">
                      <label for="due_date">due date</label>
                      <date-picker name="due_date" ></date-picker>
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <button
                          class="flex items-center border-b font-bold focus:outline-none mb-4 button-color-link mt-10"
                          type="button"
                          >
                          override
                      </button>
                  </div>
                  <div class="form-col">
                      <AmountField
                        name="amount_credit"
                        label="amount"

                        :edit-mode="true"
                        :compute-width="false"
                        text-class="text-left"
                      />
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">

                          <label >
                            description
                          </label>

                      <textarea
                      type="text"
                      class="form-input"
                      />
                  </div>
              </div>
          </div>
      </div>
    </FinalForm>
</div>

</template>

<script>
import {FinalForm} from 'vue-final-form';
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import DatePicker from "@/components/ui/datepicker/DatePicker";
import Dropdown from "@/components/ui/Dropdown";
import AmountField from "@/components/form/AmountField";


export default {
    components: { Dropdown, DatePicker, FinalForm, AmountField },
    mixins: [ ModalNavigation, NotifyMixin ],
    data() {
        return {
            selectedCatg: 'charge'
        }
    },
    created() {
        this.initValidator();
        this.validator.extend('less_than_zero', function(value) {
            return !(value < 0);
        });
        this.validator.setCustomErrorMessages({
            'charge.less_than_zero': 'Enter only positive values.',
        });
    }
}
</script>

<style scoped>
.color-text{
    color: var(--highlightColor500);
}

.button-color-link{
    border-color: var(--highlightColor500);
    color: var(--highlightColor500);
}

.button-color{
  border-color: var(--highlightColor500);
  background-color: var(--highlightColor300);
}
  .form {
    width: 55%;
  }
  ul:before {
    content: attr(aria-label);
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }
  ul > li {
    @apply py-3 font-light text-black;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
</style>
